var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-container"},[[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"tile":"","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'Projecting-detail',
              params: {
                id: _vm.$route.params.id,
              },
            })}}},[_vm._v("Zpět")])],1)],1),_c('h3',{staticClass:"mt-5 mb-8 col-secodary-2"},[_vm._v("Nová složka")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Název složky *"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('input',{ref:"documentFile",staticClass:"input--file",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":""},on:{"input":function($event){return _vm.handleInputFile($event.target.files)}}}),_c('div',{staticClass:"d-flex"},[_vm._l((_vm.documents),function(document,documentIdx){return _c('div',{key:documentIdx},[_c('div',{staticClass:"document-added mr-6"},[_c('div',{staticClass:"document-text"},[_vm._v("Dokument")]),_c('div',{staticClass:"document-close"},[_c('button',{on:{"click":function($event){return _vm.handleDeleteFile(document.documentId, documentIdx)}}},[_c('img',{attrs:{"src":require('@/assets/zavrit.svg'),"alt":""}})])])]),_c('v-text-field',{staticStyle:{"width":"150px"},attrs:{"label":"Název dokumentu *"},model:{value:(document.name),callback:function ($$v) {_vm.$set(document, "name", $$v)},expression:"document.name"}})],1)}),_c('button',{staticClass:"document-input-add",on:{"click":function($event){return _vm.handleClick('documentFile')}}},[_c('div',{staticClass:"document-input-add-plus"},[_vm._v("+")]),_c('div',{staticClass:"document-input-add-text"},[_vm._v("Vložit dokument")])])],2),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","tile":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push({
              name: 'Projecting-detail',
              params: {
                id: _vm.$route.params.id,
              },
            })}}},[_vm._v("Zrušit")]),_c('v-btn',{attrs:{"small":"","tile":"","color":"primary"},on:{"click":_vm.store}},[_vm._v("Uložit")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }